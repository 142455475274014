import React, { Fragment, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useCloseDatePicker } from "../../Common/Functions/useCloseDatePicker";
import { useDrag, useDrop } from 'react-dnd';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from '@material-ui/core/InputAdornment';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReorderIcon from '@material-ui/icons/Reorder';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { SortableHandle } from 'react-sortable-hoc';

import AirportSearch from "./AirportSearch";

import { SetOnewayOriginDate, SetRoundtripReturnDate, SetMultiDestStartDate, SetOnewayClass, SetOpenDatePickers,
    SetRoundtripClass, SetMultiDestClass, SubJourneyToFlight, RevertJourneys } from "../../../Actions/Flight";

import moment from 'moment';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    textFieldInput: {
        "borderRadius": 0,
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "fontWeight": 400,
        // color: '#0000008A',
        "paddingTop": 4,
        "paddingBottom": 4,
        "&& .mui-jss-MuiSelect-iconOutlined": {
            display: "none"
        }
    },
    textFieldLabel: {
        fontWeight: 900
    },
    withBorder: {
        borderRadius: "0px 8px 8px 0px"
    },
    withFullBorder: {
        borderRadius: 8
    },
    startAdornment: {
        marginRight: 12
    },
    spacer: {
        padding: "8px 0px"
    },
    flightText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: 12,
        // color: '#0000008A',
        letterSpacing: 1.25,
        textTransform: "uppercase",
        textAlign: "center"
    },
    spacerLateral: {
        marginLeft: 12
    },
    ftMove: {
        cursor: "move"
    },
    ftMoving: {
        opacity: "0.2"
    }
}));


const SearchFlightBar = (({ display, group_index, journey_type, journey_index, journey_step }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallLaptop = useMediaQuery("(max-width:1366px)");
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));

    const language = useSelector(store => store.header.language);
    const flight_groups = useSelector(store => store.flight.flight_groups);
    const open_date_pickers = useSelector(store => store.flight.open_date_pickers);
    const start_date = useSelector(store => store.trip.start_date);
    const end_date = useSelector(store => store.trip.end_date);
    const flight_param = useSelector(store => store.itinerary_type.flight_circuit);

    const classTypeLabel = useRef(null);
    const classTypeLabelWidth = classTypeLabel.current ? classTypeLabel.current.clientWidth : 116;
    const datePickerRef = useRef(null);
    const datePopperRef = useRef(null);
    const dragRef = useRef(null);

    const ItemTypes = {
        ITEM: 'item'
    };

    const cabin = {
        ECONOMY: 0,
        PREMIUM_ECONOMY: 1,
        BUSINESS: 2,
        FIRST: 3
    };

    useCloseDatePicker(datePickerRef, datePopperRef, open_date_pickers, group_index);

    useEffect(() => {
        if (journey_step.start_date === null) {
            if (journey_index === 0) {
                journey_step.start_date = start_date;
            } else {
                journey_step.start_date = flight_groups[group_index].journey[journey_index - 1].start_date;
            }
        }
    }, []);
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!dragRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = `${journey_index}`;
            if (dragIndex === hoverIndex) {
                return;
            }
            dispatch({ type: "FLIGHT_SWAP_MULTIDEST_JOURNEY", payload: { drag_index: dragIndex, hover_index: hoverIndex, group_index: group_index } });
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: { index: `${journey_index}` },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    drag(drop(dragRef));
    const handleFlightDate = (value) => {
        let formatted_value = moment(value);
        if (journey_type === 3 || journey_index === 0) {
            // one way or roundtrip, multi destination departure
            dispatch(SetOnewayOriginDate(formatted_value, group_index));
        } else if (journey_type === 1 && journey_index === 1) {
            // roundtrip return
            dispatch(SetRoundtripReturnDate(formatted_value, group_index));
        } else if (journey_type === 2 && journey_index !== 0) {
            // multidest other flight
            dispatch(SetMultiDestStartDate(formatted_value, group_index, journey_index));
        }
    };

    const handleFlightDateRoundtrip = (dates) => {
        if (dates[0] !== null) {
            if (journey_type === 1) {
                if (journey_index === 1) {
                    dispatch(SetRoundtripReturnDate(dates[0], group_index));
                } else {
                    dispatch(SetOnewayOriginDate(dates[0], group_index));
                }
            } else {
                dispatch(SetOnewayOriginDate(dates[0], group_index));
            }
        }
        if (dates[1] !== null && dates[1].format("YYYY-MM-DD[T]HH:mm:ss[Z]") !== dates[0].format("YYYY-MM-DD[T]HH:mm:ss[Z]")) {
            dispatch(SetRoundtripReturnDate(dates[1], group_index));
        }
    };

    const handleClassType = (value) => {
        if (journey_type === 3 || journey_index === 0) {
            // one way or roundtrip, multi destination departure
            dispatch(SetOnewayClass(value, group_index));
        } else if (journey_type === 1 && journey_index === 1) {
            // roundtrip return
            dispatch(SetRoundtripClass(value, group_index));
        } else if (journey_type === 2 && journey_index !== 0) {
            // multidest other flight
            dispatch(SetMultiDestClass(value, group_index, journey_index));
        }
    };

    const handleOpen = () => {
        let temp_open_date_pickers = open_date_pickers.slice();
        let temp_open_date_pickers_group = temp_open_date_pickers[group_index].slice();
        temp_open_date_pickers_group[journey_index] = !temp_open_date_pickers_group[journey_index];
        temp_open_date_pickers[group_index] = temp_open_date_pickers_group;
        dispatch(SetOpenDatePickers(temp_open_date_pickers));
    };

    const handleClose = () => {
        let temp_open_date_pickers = open_date_pickers.slice();
        let temp_open_date_pickers_group = temp_open_date_pickers[group_index].slice();
        temp_open_date_pickers_group[journey_index] = false;
        temp_open_date_pickers[group_index] = temp_open_date_pickers_group;
        dispatch(SetOpenDatePickers(temp_open_date_pickers));
    };

    const removeFlightMultiDest = () => {
        let temp_open_date_pickers = open_date_pickers.slice();
        let temp_open_date_pickers_group = temp_open_date_pickers[group_index].slice();
        temp_open_date_pickers_group.splice(journey_index, 1);
        temp_open_date_pickers[group_index] = temp_open_date_pickers_group;
        dispatch(SetOpenDatePickers(temp_open_date_pickers));
        dispatch(SubJourneyToFlight(group_index, journey_index));
    };
    // const DragHandle = SortableHandle(() => <span> <IconButton size="small" className={classes.spacerLateral}> <ReorderIcon /> </IconButton> </span>)
    return (
        <Grid ref={ dragRef } data-handler-id={ handlerId }>
            <Grid container alignItems={'center'} justify="flex-start" className={classes.spacer} direction={isHandHeld ? "column" : "row"} spacing={isHandHeld ? 2 : 0}>
                <Grid item xs={isHandHeld ? 12 : 1} className={classes.flightText}>
                    {
                        journey_type === 1 && ((journey_index === 0 && t("filters.go")) || (journey_index === 1 && t("filters.return")))
                    }
                    {
                        !isHandHeld && journey_type === 2 && t("global.flight") + " " + (journey_index + 1)
                    }
                    {
                        isHandHeld && journey_type === 2 && flight_groups[group_index].journey.length > 1 && (
                            <Grid container alignItems="center" justify="center">
                                <Grid item className={`${classes.flightText} ${classes.blue}`}>
                                    {
                                        t("global.flight") + " " + (journey_index + 1)
                                    }
                                </Grid>
                                <Grid item>
                                    <IconButton size="small" className={classes.spacerLateral} onClick={removeFlightMultiDest}> <CloseIcon className={classes.blue} /> </IconButton>
                                </Grid>
                                {
                                    journey_index !== 0 && (
                                        <Grid item>
                                            <span className={ clsx(classes.ftMove, { [classes.ftMoving]: isDragging }) }> <IconButton size="small" className={classes.spacerLateral}> <ReorderIcon /> </IconButton> </span>
                                            {/* <DragHandle /> */}
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
                <Grid item xs={isHandHeld ? 12 : 3} style={ isHandHeld ? { width: "100%", backgroundColor: 'white' } : { backgroundColor: 'white' }}>
                    <AirportSearch display={display} origin_or_dest="origin" group_index={group_index} journey_type={journey_type} journey_index={journey_index} journey_airport={journey_step.origin} label={t("flight_groups.departure")} />
                </Grid>
                <Grid item xs={isHandHeld ? 12 : 3} style={ isHandHeld ? { width: "100%", backgroundColor: 'white' } : { backgroundColor: 'white' }}>
                    <AirportSearch display={display} origin_or_dest="destination" group_index={group_index} journey_type={journey_type} journey_index={journey_index} journey_airport={journey_step.destination} label={t("flight_groups.destination")} />
                </Grid>
                <Grid item xs={isHandHeld ? 12 : 2} ref={datePickerRef} style={ isHandHeld ? { width: "100%", backgroundColor: 'white' } : { backgroundColor: 'white' }}>
                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ language }>
                        <Grid>
                            {
                                journey_type !== 1 && (
                                    <DatePicker
                                        label={t("flight_groups.flight_date")}
                                        value={journey_step.start_date}
                                        onChange={(newValue) => handleFlightDate(newValue)}
                                        InputAdornmentProps={{ position: 'start' }}
                                        OpenPickerButtonProps={{ onClick: handleOpen } }
                                        openPickerIcon={<CalendarTodayIcon />}
                                        open={open_date_pickers[group_index][journey_index]}
                                        onClose={handleClose}
                                        PopperProps={{ ref: datePopperRef, anchorEl: datePickerRef.current }}
                                        minDate={journey_index > 0 ? new Date(flight_groups[group_index].journey[journey_index - 1].start_date) : new Date(start_date)}
                                        maxDate={new Date(end_date)}
                                        renderInput={(props) =>
                                            (<TextField
                                                {...props}
                                                onClick={handleOpen}
                                                InputProps={{ ...props.InputProps, className: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : ""}` }}
                                                helperText={""}
                                                error={false}
                                                fullWidth={true}
                                                InputLabelProps={{ ...props.InputLabelProps, className: classes.textFieldLabel }}
                                                placeholder="jj/mm/aaaa"
                                                variant="outlined"
                                                size="small"
                                            />)
                                        }
                                    />
                                )
                            }
                            {
                                journey_type === 1 && flight_groups[group_index].journey.length > 1 && (
                                    <DateRangePicker
                                        label={t("flight_groups.flight_date")}
                                        startText={ t("flight_groups.flight_date") }
                                        endText={ t("flight_groups.flight_date") }
                                        value={journey_index === 0 ? [journey_step.start_date, flight_groups[group_index].journey[1].start_date] : [flight_groups[group_index].journey[0].start_date, journey_step.start_date]}
                                        onChange={(dates) => handleFlightDateRoundtrip(dates)}
                                        InputAdornmentProps={{ position: 'start' }}
                                        OpenPickerButtonProps={{ onClick: handleOpen } }
                                        open={open_date_pickers[group_index][journey_index]}
                                        onClose={handleClose}
                                        PopperProps={{ ref: datePopperRef }}
                                        minDate={journey_index > 0 ? new Date(flight_groups[group_index].journey[journey_index - 1].start_date) : new Date(start_date)}
                                        maxDate={new Date(end_date)}
                                        calendars={isSmallLaptop ? 2 : 3}
                                        desktopModeMediaQuery={ '@media (min-width: 1024px)' }
                                        renderInput={(startProps, endProps) => {
                                            return (
                                                <Fragment>
                                                    {
                                                        journey_index === 0 && (
                                                            <TextField
                                                                {...startProps}
                                                                onClick={handleOpen}
                                                                InputProps={{
                                                                    ...startProps.InputProps,
                                                                    className: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : ""}`,
                                                                    startAdornment: (
                                                                        <InputAdornment className={`${classes.startAdornment}`}>
                                                                            <CalendarTodayIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                helperText={""}
                                                                error={false}
                                                                fullWidth={true}
                                                                InputLabelProps={{ ...startProps.InputLabelProps, className: classes.textFieldLabel }}
                                                                placeholder="jj/mm/aaaa"
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )
                                                    }
                                                    {
                                                        journey_index === 1 && (
                                                            <TextField
                                                                {...endProps}
                                                                onClick={handleOpen}
                                                                InputProps={{
                                                                    ...endProps.InputProps,
                                                                    className: `${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : ""}`,
                                                                    startAdornment: (
                                                                        <InputAdornment className={`${classes.startAdornment}`}>
                                                                            <CalendarTodayIcon />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                helperText={""}
                                                                error={false}
                                                                fullWidth={true}
                                                                InputLabelProps={{ ...endProps.InputLabelProps, className: classes.textFieldLabel }}
                                                                placeholder="jj/mm/aaaa"
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )
                                                    }
                                                </Fragment>
                                            );
                                        }}
                                    />
                                )
                            }
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={isHandHeld ? 12 : 2} style={ isHandHeld ? { width: "100%", backgroundColor: 'white' } : { backgroundColor: 'white' }}>
                    <FormControl variant="outlined" style={{ width: "100%" }} size="small">
                        <InputLabel id={`class-type-${group_index}-${journey_index}`} ref={classTypeLabel}>
                            { t("flight_groups.class_type") }
                        </InputLabel>
                        {
                            (flight_param.length === 0 || flight_param[0].cabin === null || flight_param[0].cabin.length === 0) &&
                            <Select
                                labelId={`class-type-${group_index}-${journey_index}`}
                                id={`select-class-type-${group_index}-${journey_index}`}
                                value={journey_step.class_type}
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(event) => handleClassType(event.target.value)}
                                input={
                                    <OutlinedInput
                                        id={`input-class-type-${group_index}-${journey_index}`}
                                        labelWidth={classTypeLabelWidth}
                                        label={t("flight_groups.class_type")}
                                        autoComplete="off"
                                        className={`${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : classes.withBorder}`}
                                        startAdornment={<InputAdornment className={classes.startAdornment}> <AirlineSeatReclineExtraIcon /> </InputAdornment>}
                                    />
                                }
                            >
                                <MenuItem value={0}>
                                    { t("flight.class.eco") }
                                </MenuItem>
                                <MenuItem value={1}>
                                    { t("flight.class.premium_eco") }
                                </MenuItem>
                                <MenuItem value={2}>
                                    { t("flight.class.business") }
                                </MenuItem>
                                <MenuItem value={3}>
                                    { t("flight.class.first") }
                                </MenuItem>
                            </Select>
                        }
                        {
                            flight_param.length !== 0 && flight_param[0].cabin.length !== 0 &&
                            <Select
                                labelId={`class-type-${group_index}-${journey_index}`}
                                id={`select-class-type-${group_index}-${journey_index}`}
                                value={journey_step.class_type}
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(event) => handleClassType(event.target.value)}
                                input={
                                    <OutlinedInput
                                        id={`input-class-type-${group_index}-${journey_index}`}
                                        labelWidth={classTypeLabelWidth}
                                        label={t("flight_groups.class_type")}
                                        autoComplete="off"
                                        className={`${classes.textFieldInput} ${isHandHeld ? classes.withFullBorder : classes.withBorder}`}
                                        startAdornment={<InputAdornment className={classes.startAdornment}> <AirlineSeatReclineExtraIcon /> </InputAdornment>}
                                    />
                                }
                            >
                                {
                                    flight_param[0].cabin.toSorted((a, b) => cabin[a] - cabin[b]).map(cabin_choice => {
                                        return (
                                            <MenuItem value={cabin[cabin_choice]} key={cabin_choice}>{t(`flight_search.cabin_class.${cabin_choice.toLowerCase()}`)}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    {
                        !isHandHeld && journey_type === 2 && flight_groups[group_index].journey.length > 1 && (
                            <Grid container>
                                <Grid item>
                                    <IconButton size="small" className={classes.spacerLateral} onClick={removeFlightMultiDest}> <CloseIcon /> </IconButton>
                                </Grid>
                                {
                                    journey_index !== 0 && (
                                        <Grid item>
                                            <span className={ clsx(classes.ftMove, { [classes.ftMoving]: isDragging }) } > <IconButton size="small" className={classes.spacerLateral}> <ReorderIcon /> </IconButton> </span>
                                            {/* <DragHandle /> */}
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            {
                journey_type === 1 && journey_index === 0 && (
                    <Grid container alignItems={'center'} justify={isHandHeld ? "center" : "flex-start"} style={{ margin: "-15px 0px" }}>
                        <Grid item xs={1} className={classes.flightText}>
                            <IconButton size="small" onClick={() => dispatch(RevertJourneys(group_index))}>
                                <SwapVerticalCircleIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
});

export default React.memo(SearchFlightBar);
