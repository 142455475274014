//Dependencies
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//Common
import { CartCancellationRules } from './CartCancellationRules';
import { isProductSamePackage } from './utils/isProductSamePackage';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import { AppState } from '../../Reducers/Reducers';

type Props = {
    stackNumber: number | null,
    stackInfoId: number | null
}

export function CartStackedCancellationRules(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const data_trip = useSelector((state: AppState) => state.trip.data_trip);
    const [edit, setEdit] = useState(false);
    const info = useMemo(() => {
        return data_trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
    }, [
        props.stackNumber,
        props.stackInfoId,
        data_trip
    ]);

    const onValidateManualCancellationRules = (noRefund: boolean, cancellationRules: string) => {
        const { headers } = CheckBeforeRequest();
        const stackInfo = (data_trip?.stack_info ?? []).filter((item) => {
            return !isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
        const correspondingInfo = data_trip?.stack_info?.find((item) => {
            return isProductSamePackage(
                {
                    stack_number: item.stack_number,
                    stack_info_id: item.is_custom && !props.stackInfoId ?
                        props.stackInfoId :
                        item.id
                },
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        });
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
            data: {
                stack_info: (stackInfo as unknown[]).concat([
                    {
                        ...correspondingInfo,
                        booking_errors: undefined,
                        provider: undefined,
                        provider_id: correspondingInfo?.provider?.id,
                        no_refund: noRefund,
                        cancel_condition_text: cancellationRules ?? ''
                    }
                ])
            }
        }).then(response => {
            dispatch({ type: 'TRIP_SET_DATA_TRIP', payload: { data_trip: response.data } });
            setEdit(false);
        }).catch(error => {
            console.log(error);
        });
    };

    return (
        <CartCancellationRules
            type="package"
            id={null}
            open={edit}
            text={info?.cancel_condition_text ?? ''}
            moved_with_recalc_cancel_condition_text={false}
            noRefund={info?.no_refund ?? false}
            isCustom={false}
            onConfirmCancelConditionRecalc={() => null}
            onToggle={() => setEdit((state) => !state)}
            onValidate={onValidateManualCancellationRules}
        />
    );
}
