import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Transition from "../../Common/Transition";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    bold: {
        fontWeight: "bold"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textRight: {
        textAlign: "right"
    },
    paxGrid: {
        padding: "8px 16px",
        width: 330
    },
    spacer: {
        padding: "4px 0px"
    },
    lightgrey: {
        color: '#00000040'
    }
}))

const DetailedPriceButton = (({ flight }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isHandHeld = useMediaQuery(theme.breakpoints.down(960));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    const language = useSelector(store => store.header.language);
    const trip_currency = useSelector(store => store.trip.currency);

    const [openDetails, setOpenDetails] = useState(null);

    const sumTaxes = (tax_list) => {
        let total_taxes = 0;
        tax_list.map((tax) => {
            total_taxes += parseFloat(tax.selling_price);
        })
        total_taxes = total_taxes;
        return total_taxes
    }
    return (
        <Grid item sx={{ lineHeight: 1 }}>
            <Typography
                variant="caption"
                onClick={(event) => setOpenDetails(event.currentTarget)}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
                {t('cart-material.price-detail')}
            </Typography>
            {
                isHandHeld && (
                    <Dialog open={Boolean(openDetails)} fullScreen TransitionComponent={Transition}>
                        <DialogContent style={{ padding: isMobile ? "20px 6px" : "20px 30px" }}>
                            {
                                flight.adult !== null && (
                                    <Grid>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.ticket_ADT") + ` (x ${flight.adult.number})`}
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => setOpenDetails(false)}>
                                                    <CloseIcon style={{ margin: "-20px -12px 0px 0px" }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.flight_without_taxe")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.adult.total_price)}
                                                {` x ${flight.adult.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.taxe_price")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.adult.taxes))}
                                                {` x ${flight.adult.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.total") + " " + t("flight_search.adult")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.adult.total_price) + sumTaxes(flight.adult.taxes)))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.children !== null && (
                                    <Grid>
                                        <Divider />
                                        <Grid>
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {t("flight_search.ticket_CNN") + ` (x ${flight.children.number})`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {t("flight_search.flight_without_taxe")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.children.total_price)}
                                                    {` x ${flight.children.number}`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {t("flight_search.taxe_price")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.children.taxes))}
                                                    {` x ${flight.children.number}`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {t("flight_search.total") + " " + t("flight_search.child")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.children.total_price) + sumTaxes(flight.children.taxes)))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                flight.baby !== null && (
                                    <Grid>
                                        <Divider />
                                        <Grid>
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {t("flight_search.ticket_INF") + ` (x ${flight.baby.number})`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {t("flight_search.flight_without_taxe")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.baby.total_price)}
                                                    {` x ${flight.baby.number}`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {t("flight_search.taxe_price")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.baby.taxes))}
                                                    {` x ${flight.baby.number}`}
                                                </Grid>
                                            </Grid>
                                            <Grid container alignItems="center" justify="space-between">
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {t("flight_search.total") + " " + t("flight_search.baby")}
                                                </Grid>
                                                <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                    {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.baby.total_price) + sumTaxes(flight.baby.taxes)))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </DialogContent>
                    </Dialog>
                )
            }
            {
                !isHandHeld && (
                    <Menu
                        id="detailed_price"
                        anchorEl={openDetails}
                        keepMounted
                        open={Boolean(openDetails)}
                        onClose={() => setOpenDetails(false)}
                        disableScrollLock={true}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                        {
                            flight.adult !== null && (
                                <Grid className={classes.paxGrid}>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                            {t("flight_search.ticket_ADT") + ` (x ${flight.adult.number})`}
                                        </Grid>
                                        <Grid item>
                                            <IconButton size="small" onClick={() => setOpenDetails(false)}>
                                                <CloseIcon style={{ margin: "-20px -12px 0px 0px" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                            {t("flight_search.flight_without_taxe")}
                                        </Grid>
                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                            {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.adult.total_price)}
                                            {` x ${flight.adult.number}`}
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                            {t("flight_search.taxe_price")}
                                        </Grid>
                                        <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                            {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.adult.taxes))}
                                            {` x ${flight.adult.number}`}
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                            {t("flight_search.total") + " " + t("flight_search.adult")}
                                        </Grid>
                                        <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                            {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.adult.total_price) + sumTaxes(flight.adult.taxes)) * flight.adult.number)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            flight.children !== null && (
                                <Grid>
                                    <Divider />
                                    <Grid className={classes.paxGrid}>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.ticket_CNN") + ` (x ${flight.children.number})`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.flight_without_taxe")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.children.total_price)}
                                                {` x ${flight.children.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.taxe_price")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.children.taxes))}
                                                {` x ${flight.children.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.total") + " " + t("flight_search.child")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.children.total_price) + sumTaxes(flight.children.taxes)) * flight.children.number)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            flight.baby !== null && (
                                <Grid>
                                    <Divider />
                                    <Grid className={classes.paxGrid}>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.ticket_INF") + ` (x ${flight.baby.number})`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.flight_without_taxe")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(flight.baby.total_price)}
                                                {` x ${flight.baby.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {t("flight_search.taxe_price")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.lightgrey}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sumTaxes(flight.baby.taxes))}
                                                {` x ${flight.baby.number}`}
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {t("flight_search.total") + " " + t("flight_search.baby")}
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontSize16} ${classes.bold}`}>
                                                {new Intl.NumberFormat(language, { style: 'currency', currency: trip_currency.iso_code, minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((parseFloat(flight.baby.total_price) + sumTaxes(flight.baby.taxes)) * flight.baby.number)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Menu>
                )
            }
        </Grid>
    )
})

export default React.memo(DetailedPriceButton);