import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AvailabilityCard from "./AvailabilityCard";
import AvailabilityCalendar from "./AvailabilityCalendar";
import MoreAvailabilityDates from "./MoreAvailabilityDates";

const useStyles = makeStyles(theme => ({
    bold: {
        fontWeight: "bold"
    },
    dateSpacer: {
        paddingTop: 16
    },
    orangeBorderButton: {
        color: "#E6592F",
        borderColor: "#E6592F",
        backgroundColor: "#FFFFFF"
    }
}))

const CustomPoiAvailability = ({ arrivalDate, departureDate, availabilities, assignedGroups, view,  displayCalendarDate, setDisplayCalendarDate, seeAllAvailabilities, setSeeAllAvailabilities, setMinCustomTime, setMaxCustomTime,  selectActivity, manualVariant, current_groups, poi_type }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedVariantPrice, setSelectedVariantPrice] = useState(null);
    const [dayNumber, setDayNumber] = useState(1);
    useEffect(() => {
        if (availabilities.length !== 0 && assignedGroups.length !== current_groups.length) {
            if (poi_type) {
                setSelectedVariantPrice(availabilities[assignedGroups.length].scheduled_prices);
            } else {
                if (manualVariant !== "") {
                    let find_schedules = availabilities[assignedGroups.length].choices.find(choice => choice.id === manualVariant.id);
                    if (find_schedules !== undefined) {
                        setSelectedVariantPrice(find_schedules.scheduled_prices);
                    } else {
                        setSelectedVariantPrice(null);
                    }
                } else {
                    setSelectedVariantPrice(availabilities[assignedGroups.length].scheduled_prices);
                }
            }
            if (manualVariant.duration !== undefined && manualVariant.duration !== null) {
                if (manualVariant.duration >= 1440) {
                    setDayNumber(Math.floor(manualVariant.duration/1440));
                }
            }
        }
    }, [manualVariant, availabilities, assignedGroups.length]);
    return (
        <Grid>
            {
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && selectedVariantPrice !== null && selectedVariantPrice.length !== 0 && (
                    <Typography className={`${classes.bold} ${classes.dateSpacer}`} variant='body2'> { t("poi.select_date", {nb: dayNumber}) + " :" } </Typography>
                )
            }
            {
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && selectedVariantPrice !== null && selectedVariantPrice.length !== 0 && !availabilities[assignedGroups.length].poi_type &&
                selectedVariantPrice.map((day_price, availability_index) => {
                    if (day_price.areBounds && day_price.hour.length !== 0){ 
                        // set hours limit of activity
                        setMinCustomTime(day_price.hour[0].length === 4 ? '0' + day_price.hour[0] : day_price.hour[0]);
                        setMaxCustomTime(day_price.hour[1].length === 4 ? '0' + day_price.hour[1] : day_price.hour[1]);
                    }
                    if (availability_index < 3){
                        return(
                            <AvailabilityCard key={`${day_price.date}-${day_price.hour.length >= 1 ? day_price.hour[0] : ""}`} day_prices={day_price} selectActivity={selectActivity} poi_type={poi_type}/>
                        )
                    }
                })
            }
            {
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && availabilities[assignedGroups.length].poi_type && availabilities[assignedGroups.length].scheduled_prices !== undefined &&
                availabilities[assignedGroups.length].scheduled_prices.map((day_prices, availability_index) => {
                    if (day_prices.areBounds && day_price.hour.length !== 0){ 
                        // set hours limit of activity
                        setMinCustomTime(day_prices.hour[0].length === 4 ? '0' + day_prices.hour[0] : day_prices.hour[0]);
                        setMaxCustomTime(day_prices.hour[1].length === 4 ? '0' + day_prices.hour[1] : day_prices.hour[1]);
                    }
        
                    if (availability_index < 3){
                        return(
                            <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length >= 1 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} poi_type={poi_type}/>
                        )
                    }
                })
            }
            {
                //show variant availabilities
                // availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" &&
                // availabilities[assignedGroups.length].choices.length !== 0 && day_prices.scheduled_prices.length === 0 && selectedVariantPrice !== null && selectedVariantPrice.map((day_prices, availability_index) => {
                //     if (availability_index < 3) {
                //         return (
                //             <AvailabilityCard key={`${day_prices.date}-${day_prices.hour.length !== 0 ? day_prices.hour[0] : ""}`} day_prices={day_prices} selectActivity={selectActivity} />
                //         );
                //     }
                // })
            }
            {
                // Button to show all available dates
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && selectedVariantPrice !== null && selectedVariantPrice.length !== 0 &&
                selectedVariantPrice.length > 3 && (
                // availabilities[assignedGroups.length].scheduled_prices.length > 3 && (
                    <Grid className={classes.spacer} style={{ textAlign: "center" }}>
                        <Button variant="outlined" size="small" className={classes.orangeBorderButton} onClick={() => setSeeAllAvailabilities(true)}>
                            { t("poi.see_more_dates") }
                        </Button>
                    </Grid>
                )
            }
            {
                // all available dates for product without variants
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "card" && selectedVariantPrice !== null && selectedVariantPrice.length !== 0 && (
                    <MoreAvailabilityDates openModal={seeAllAvailabilities} setOpenModal={setSeeAllAvailabilities} scheduled_prices={selectedVariantPrice} selectActivity={selectActivity} poi_type={poi_type}/>
                )
            }
            {
                // calendar view
                availabilities.length !== 0 && assignedGroups.length !== current_groups.length && view === "calendar" && (
                    <AvailabilityCalendar
                        arrivalDate={arrivalDate}
                        departureDate={departureDate}
                        availabilities={availabilities}
                        assignedGroups={assignedGroups}
                        displayCalendarDate={displayCalendarDate}
                        setDisplayCalendarDate={setDisplayCalendarDate}
                        selectActivity={selectActivity}
                        selectedVariantPrice={selectedVariantPrice}
                        manualVariant={manualVariant}
                        poi_type={poi_type}
                        dayNumber={dayNumber}
                    />
                )
            }
        </Grid>
    )
}

export default React.memo(CustomPoiAvailability);